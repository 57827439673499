import React, { FC, useState, ChangeEvent, useEffect } from "react";
import { Redirect } from "react-router-dom";

import './Report.scss';

import {
    FormControlLabel,
    Switch,
    Table, TableRow, TableCell, TableHead, TableBody, TableFooter, TablePagination, Typography, Select, MenuItem
    } from "@material-ui/core";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { SurveyReport, ReportSection, Question, ReportComment, BreakdownHeader } from "../model/report";
import { QuestionBlock } from "./Question";
import Catch from "../Catch";
import { Bar } from "../Bar";
import { Backend, ReportResponse } from "../backend/Backend";
import { Condition } from "./Condition";
import I from 'immutable';
import { StreamDispatcher } from "../backend/Stream";

interface SelectorProps {
    current?: BreakdownHeader;
    breakdowns?: BreakdownHeader[];
    onSelect: (b?: BreakdownHeader) => void;
}

const BreakdownSelector: FC<SelectorProps> = ({ current, breakdowns, onSelect }) => {
    const [selected, select] = useState<BreakdownHeader | undefined>(current);

    if (!(breakdowns && breakdowns.length)) {
        const placeholder = { title: '', prefix: ''};
        const control = <Switch checked={selected !== undefined}
            onChange={(e) => {
                const value = e.target.checked ? placeholder : undefined;
                onSelect(value);
                select(value);
            }}/>;
        return <div className="breakdown-toggle">
            <FormControlLabel
                control={control}
                label="See breakdown"
                labelPlacement="start"
            />
        </div>;
    }

    const onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const value = event.target.value as number;
        onSelect(value >= 0 ? breakdowns[value] : undefined);
        select(breakdowns[value]);
    }

    const value = selected ? breakdowns.findIndex(b => b.title === selected.title) : -1;
    return <Select value={value} onChange={onChange} >
        <MenuItem value={-1}>
            Choose breakdown
        </MenuItem>
        {breakdowns.map((b, i) => <MenuItem value={i} key={i}>{b.title}</MenuItem>)}
    </Select>
}

interface SectionProps {
    reportId: string;
    section: ReportSection;
    comments: StreamDispatcher<ReportComment>;
    breakdowns?: BreakdownHeader[];
}

const Section: FC<SectionProps> = ({ reportId, section, comments, breakdowns }) => {
    const [breakdown, onSelect] = useState<BreakdownHeader>();
    const { questions } = section;

    const hasBreakdown = section.questions.some((q) => {
        const { breakdown } = q.answers[0];
        return breakdown && breakdown.length > 1;
    });

    return <section>
        <p className="header">
            <h2>{section.title}</h2>
            <div className="grow"/>

            <Condition cond={hasBreakdown}>
                <BreakdownSelector current={breakdown} breakdowns={breakdowns} onSelect={onSelect}/>
            </Condition>
        </p>
        <p className="questions">
        {
            questions.map((q) => <QuestionBlock breakdown={breakdown} question={q} {...{reportId, comments}} />)
        }
        </p>
    </section>
};


const highlight = (value: any) => <span className="highlight">{value}</span>

const ReportSummary: FC<{ report: SurveyReport }> = ({ report }) => {
    const { header } = report;
    return <section className="summary">
        <h2>Report Summary: {highlight(header.organization)}</h2>
        <p>
            <div className="report-location">Location: {highlight(header.place)}</div>
            <div className="report-date">Date of survey: {highlight(header.date)}</div>
            <div className="report-respondents">Number of respondents: {highlight(header.respondents)}</div>
            { header.description ?
                <div className="report-type">Type of survey: {highlight(header.description)}</div>
                :
                null
            }
        </p>
    </section>;
}

type CommentMap = I.Map<string, I.List<ReportComment>>;

export const Report: FC<{ report: SurveyReport, comments: StreamDispatcher<ReportComment>}> = ({ report, comments }) =>
    <div className="report">
        <ReportSummary {...{report}}/>
        {report.results.map((section, key) => <Section reportId={report.uuid} breakdowns={report.header.breakdowns} {...{section, key, comments}}/>)}
    </div>;


interface LoadState {
    report?: SurveyReport;
    error?: Error;
    subscription?: { cancel: () => void };
    commentsDispatcher?: StreamDispatcher<ReportComment>;
}

export class LoadReport extends React.Component<{ id: string}, LoadState> {
    constructor(props: { id: string }) {
        super(props);
        this.state = { }
    }

    componentDidMount() {
        const { id } = this.props;
        const { report, error } = this.state;
        if (!(error || report)) {
            Backend.instance.getReport(id)
                .then(report => {
                    const disp = new StreamDispatcher<ReportComment>(rc => rc.metadata.question);
                    const subscription = Backend.instance.listenComments(id, disp.publish.bind(disp));
                    this.setState({report, subscription, commentsDispatcher: disp});
                })
                .catch(error => this.setState({error}));
        }
    }

    componentWillUnmount() {
        const { subscription } = this.state;
        if (subscription) subscription.cancel();
    }

    render() {
        if (this.state.report) {
            return <Report report={this.state.report} comments={this.state.commentsDispatcher as any}/>;
        }
        return <></>;
    }
}

export const ReportPage: FC<{ id: string }> = ({ id }) => {
    const [ state, setState ] = React.useState<{ redirect?: string }>({});

    if (state.redirect) {
        return <Redirect to={state.redirect}/>;
    }

    const back = () => setState({ redirect: '/reports/' });

    return <Catch>
        <Bar>
            <h3 onClick={back} style={{ cursor: 'pointer' }}>
                <ArrowBackIcon className="icon"/> Go back
            </h3>
            <div className="logo"/>
        </Bar>
        <main className="mui-fixed content">
            <LoadReport id={id}/>
        </main>
    </Catch>
};

interface ReportTableState {
    reports?: ReportResponse;
    error?: any;
    page: number;
    limit: number;
}

export const ListReports = (_: any) => {
    const [state, setState] = useState<ReportTableState>({ limit: 25, page: 0 });

    const load = async (limit: number, page: number) => {
        const skip = limit * page;
        try {
            const reports = await Backend.instance.getReports({ skip, limit })
            setState({ ...state, limit, reports, page });
        } catch (error) {
            setState({ ...state, error });
        }
    }

    useEffect(() => { if (!(state.reports || state.error)) load(state.limit, 0); });

    const changePage = (event: any, newPage: number) => {
        load(state.limit, newPage);
    };

    const setLimit = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const limit = parseInt(event.target.value, 10);
        load(limit, 0);
    }

    if (state.error) {
        throw state.error;
    }

    if (state.reports) {
        const { reports } = state;
        return <div className="report" style={{ alignItems: 'flex-start' }}>
        <p>
            <h4>Survey Reports</h4>
        </p>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Survey</TableCell>
                    <TableCell>Organization</TableCell>
                    <TableCell>Respondents</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {reports.reports.map((rep, i) =>
                <TableRow key={`report-row-${i}`}>
                    <TableCell><a href={`/reports/${rep.uuid}`}>{rep.header.place}, {rep.header.date}</a></TableCell>
                    <TableCell>{rep.header.organization}</TableCell>
                    <TableCell>{rep.header.respondents}</TableCell>
                </TableRow>
            )}
            </TableBody>
            <TableFooter>
                <TablePagination
                    page={state.page || 0}
                    count={reports.total}
                    rowsPerPage={state.limit}
                    onChangePage={changePage}
                    onChangeRowsPerPage={setLimit}/>
            </TableFooter>
        </Table></div>
    }
    return null;
}
