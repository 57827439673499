import React, { ReactNode } from "react";
import { Question, Answer } from "../model/report";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid, Legend, Text } from 'recharts';
import './Report.scss';

const DefaultLegendContent = require('recharts/lib/component/DefaultLegendContent').default as { new (): React.Component } ;

export interface ChartProps {
    question: Question;
    breakdownPrefix?: string;
    width?: string;
    height?: string;
}

const colors = {
    main: ['#3D7BEE', '#97B9F7'],
    breakdown: [
        ['#EFCCBE', '#F7E5DE'],
        ['#649CF8', '#D0E1FD'],
        ['#9BE4C1', '#CDF1E0'],
    ],
};

interface XTickProps {
    x?: number;
    y?: number;
    fill?: string;
    payload?: { value: any };
    diagonal?: boolean;
}

function normalizeLabel(label: string): string {
    if (!label) return label;
    return label.replace(/(?:(^| ))(.)/g, (c) => c.toUpperCase())
    .replace('/', ' / ')
    .replace('<=', '≤')
    .replace('>=', '≥');
}

class XTick extends React.Component<XTickProps> {
    render(): React.ReactNode {
        let { x, y, fill, payload, diagonal } = this.props;
        if (!payload) return null;

        const value = payload.value as string;
        const [angle, textAnchor] = diagonal ? [-45, 'end' as any] : [0, 'middle' as any];

        const width = value.length >= 15 ? 100 : undefined;
        const label = normalizeLabel(payload ? payload.value : '')
        return <Text fontSize={11} verticalAnchor="end" {...{angle, textAnchor, width, fill, x, y: (y||0)+10 }}>
            {label}
        </Text>;
    }
}

function barMouseProps(cols: string[]) {
    return {
        onMouseEnter: (...args: any[]) => { args[2].target.style.fill = cols[1]; },
        onMouseLeave: (...args: any[]) => { args[2].target.style.fill = cols[0]; },
    };
};

class LegendContent extends DefaultLegendContent {
    renderIcon(data: any) {
        const size = 32;
        const offset = 4;
        const side = size - 2 * offset;
        return <rect x={offset} y={offset} width={side} height={side} rx={6} fill={data.color}/>;
    }
}

interface ChartState {
    totals: { [k: string]: number };
    breakdown?: {
        order: string[];
        data: any[];
    };
}

export class Chart extends React.Component<ChartProps, ChartState> {
    constructor(props: ChartProps) {
        super(props);
        const { answers } = this.props.question;
        this.state = ({ totals: { total: answers.reduce((sum, a) => a.total + sum, 0) } });
    }

    componentDidUpdate(oldProps: ChartProps) {
        if (oldProps.breakdownPrefix === this.props.breakdownPrefix) {
            return;
        }

        const { answers } = this.props.question;
        const { breakdownPrefix } = this.props;
        if (answers[0].breakdown && breakdownPrefix !== undefined) {
            this.setBreakdown(breakdownPrefix);
        } else {
            this.setState({ breakdown: undefined })
        }
    }

    setBreakdown(prefix: string) {
        const { answers } = this.props.question;
        const matchBreakdown = (v: {name: string}) => v.name.startsWith(prefix);
        const breakdownName = (v: {name: string}) => v.name.substring(prefix.length);

        const order = answers[0].breakdown.filter(matchBreakdown).map(breakdownName);
        const data: any[] = [];
        for (const ans of answers) {
            const entry: {[k: string]: any} = { title: ans.title };
            for (const bd of ans.breakdown) {
                if (matchBreakdown(bd)) {
                    entry[breakdownName(bd)] = bd.count;
                }
            }
            data.push(entry);
        }

        this.setState({ breakdown: { data, order }});
    }

    render(): ReactNode {
        const  { answers } = this.props.question;

        let bars: any[] = [];
        let data = null;

        const { totals, breakdown } = this.state;

        if (breakdown) {
            bars = breakdown.order.map((key, i) => {
                const bdColors = colors.breakdown[i % colors.breakdown.length];
                return <Bar
                    key={`${this.props.question.id}-bar-${key}-${i}`}
                    dataKey={key}
                    fill={bdColors[0]}
                    radius={[5, 5, 0, 0]}
                    {...barMouseProps(bdColors)}/>
            });
            data = breakdown.data;
        } else {
            bars = [<Bar dataKey="total" fill={colors.main[0]} radius={[5, 5, 0, 0]} {...barMouseProps(colors.main)}/>];
            data = answers;
        }

        const formatLegend = (value: string) => <span className="legend">{value}</span>;

        const color = '#262324';
        const axisLine = {
            stroke: color,
            strokeWidth: 0.2,
        };

        const tooltipContent: React.FC<{ payload: any, label: string }> = ({ payload, label }) => {
            return <div className="recharts-default-tooltip">
            <p className="recharts-tooltip-label">{normalizeLabel(label)}</p>
            { payload.map(({ value, name }: any) => {
                const total = totals[name];
                const percent = total ? Math.round(value / total * 100) : 0;
                const prefix = name === 'total' ? '': `${normalizeLabel(name)}: `;
                return <p className="recharts-tooltip-item"><span style={{fontWeight: 500}}>{prefix}{percent}%</span> ({value} of {total})</p>
            }) }
            </div>
        };

        const horizontalTicks = answers.every(ans => /^[≤≥=<>+\d]+$/.test(ans.title))
        const tick = <XTick fill={color} diagonal={!horizontalTicks}/>;

        return <div className="chart">
            <ResponsiveContainer aspect={1.2}>
                <BarChart data={data} margin={{ top: 0, left: 0, bottom: 0, right: 0}} barSize={27 / bars.length} barGap={5}>
                    <CartesianGrid strokeOpacity={0.5} {...{axisLine}}/>
                    <XAxis dataKey="title" tickLine={false} interval={0} height={100} {...{tick, axisLine}}/>
                    <YAxis tickLine={false} tickCount={3} tick={{fontSize: 12, fill: color}} {...{axisLine}}/>
                    <Tooltip cursor={{ opacity: 0 }} content={tooltipContent}/>
                        {(this.props.breakdownPrefix !== undefined) ? <Legend content={<LegendContent/>}/> : null}
                    {bars}
                </BarChart>
            </ResponsiveContainer>
        </div>;
    }
}