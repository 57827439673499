
class DefaultMap<K, V> extends Map<K, V> {
    private fn: () => V;

    constructor(fn: () => V) {
        super();
        this.fn = fn;
    }

    get(key: K): V {
        if (!this.has(key)) {
            this.set(key, this.fn());
        }
        return super.get(key) as V;
    }
}


export type TopicFn<Event> = (e: Event) => string;
export type Callback<Event> = (e: Event[]) => void;

export class StreamDispatcher<Event> {
    private topicFn: TopicFn<Event>;
    private subscribers: DefaultMap<string, Callback<Event>[]> = new DefaultMap(() => []);

    constructor(topicFn: TopicFn<Event>) {
        this.topicFn = topicFn;
    }

    subscribe(topic: string, callback: Callback<Event>): void {
        this.subscribers.get(topic).push(callback);
    }

    publish(events: Event[]) {
        const byTopic = new DefaultMap<string, Event[]>(() => []);
        for (const event of events) {
            const topic = this.topicFn(event);
            byTopic.get(topic).push(event);
        }

        for (const [topic, events] of byTopic.entries()) {
            for (const callback of this.subscribers.get(topic)) {
                callback(events);
            }
        }
    }
}
