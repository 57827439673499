import React from "react";
import { FC } from "react";
import I from 'immutable';
import { Question, Framework, ReportComment, BreakdownHeader } from "../model/report";
import { Chart } from "./Chart";
import { PopupState } from "./Popup";
import { Popover, Typography, InputAdornment, TextField, Fade, Popper, IconButton } from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import { Status, Backend } from "../backend/Backend";
import { TextFieldProps } from "@material-ui/core/TextField";
import { StreamDispatcher } from "../backend/Stream";

interface QuestionProps {
    reportId: string;
    question: Question;
    comments: StreamDispatcher<ReportComment>;
    breakdown?: BreakdownHeader;
}

export const QuestionBlock: FC<QuestionProps> = ({ reportId, question, breakdown, comments }) => {
    return <div className="question">
        <QuestionTitle title={question.title}/>
        <QuestionStatus {...{question}}/>
        <Chart {...{question}} breakdownPrefix={breakdown ? breakdown.prefix : undefined}/>
        <Comments {...{question, comments, reportId}}/>
    </div>
};

/**
 * When possible, split the title by sentences in two parts, so that the first part
 * is at most maxLen characters long.
 */
function splitTitle(text: string, maxLen: number=25): string[] {
    if (text.length < maxLen) {
        return [text];
    }

    const tail = text.replace(/([?!.]+) /, '$1\0').split('\0');
    const pop = () => tail.splice(0, 1)[0];
    let head = pop();

    while (tail.length) {
        const next = tail[0];
        if (head.length + next.length + 1 > maxLen) {
            break;
        }
        head += ' ' + pop();
    }
    return [head, tail.join(' ')];
}

const QuestionTitle: FC<{ title: string }> = ({ title }) => {
    const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);
    const popup = new PopupState(`more-${title}`, anchor, setAnchor, { disableHover: true });

    const [mainTitle, moreTitle] = splitTitle(title);
    let elems = [
        <h5>{mainTitle}</h5>
    ];

    if (moreTitle && moreTitle.length) {
        elems = [
            <h5>{mainTitle} <a href="#" {...popup.anchorProps}
                onClick={(e) => e.preventDefault()}
                onMouseEnter={(e) => { e.preventDefault(); popup.show(e)}}
                onMouseLeave={(e) => { e.preventDefault(); popup.hide() }}>See more</a></h5>,
            <Popover {...popup.popoverProps}
                className="popover see-more"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <p>{mainTitle} {moreTitle}</p>
            </Popover>
        ];
    }

    return <div className="title">
        {elems}
    </div>
};

const QuestionStatus: FC<{ question: Question }> = ({ question }) =>
    <div className="status">
        <InfoButton {...{question}}/>
        {/* <div className="grow total">{question.answers.map(a => a.total).reduce((a, b) => a+b, 0)} responses</div> */}
        <Frameworks {...{question}}/>
    </div>


function InfoIcon(attrs: any) {
    return <img src="/img/i-icon.svg" width="20" className="info icon" {...attrs} alt=""></img>
}

const Info: FC<{ question: Question }> = ({ question }) => {
    return <div>
    <InfoIcon/>
    <h5>{question.progress.value}</h5>
    <Typography>{question.progress.description}</Typography>
    </div>;
};

const InfoButton: FC<{ question: Question }> = ({ question }) => {
    const popup = new PopupState(`info-${question.id}`, ...React.useState<HTMLElement | null>(null));

    return <>
    <InfoIcon {...popup.anchorProps}/>
    { question.progress ?
    <Popover {...popup.popoverProps}
        className="popover info"
        TransitionComponent={Fade}
        anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            transformOrigin={{
                vertical: 10,
                horizontal: 10,
            }}>

        <Info {...{question}} />
    </Popover>: null }
    </>;
};

function FrameworkIcon(attrs: any) {
    const elemAttrs: any = {...attrs};
    delete elemAttrs.name;
    const { name } = attrs;
    return <span className={`icon framework ${name}`} {...elemAttrs}>{name}</span>
}

const FrameworkPopup: FC< { questionId: string, framework: Framework } > = ({ questionId, framework }) => {
    const { name, description, title, subtitle } = framework;
    const popup = new PopupState(`fw-${questionId}-${name}`, ...React.useState<HTMLElement | null>(null));

    return <>
    <FrameworkIcon name={name} {...popup.anchorProps}/>

    { description ? <Popover {...popup.popoverProps}
                TransitionComponent={Fade}
                className="popover framework"
                anchorOrigin={{
                    vertical: -20,
                    horizontal: 69,
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
            <div>
                <FrameworkIcon name={name}/>
                <h5>{title || name}</h5>
                { subtitle ? <h6>{subtitle}</h6> : null}
                <Typography>{description}</Typography>
            </div>
        </Popover> : null
    }
    </>
};

const Frameworks: FC<{ question: Question }> = ({ question }) => {
    const { frameworks } = question;
    const elems = ( frameworks || []).map((framework, i) =>
        <FrameworkPopup key={i} questionId={question.id} {...{framework}}/>
    );
    return <div className="frameworks">{elems}</div>;
}

interface CommentsState {
    status?: Status;
    popupAnchor: null | HTMLElement;
    canSend: boolean;
    pendingComment: string;
    comments: ReportComment[];
    limit: number;
}

class Comments extends React.Component<{ reportId: string, question: Question, comments: StreamDispatcher<ReportComment> }, CommentsState> {
    constructor(props: any) {
        super(props);

        this.state = {
            canSend: false,
            popupAnchor: null,
            comments: [],
            pendingComment: '',
            limit: 3,
        };
    }

    componentDidMount() {
        if (!this.state.status) {
            Backend.instance.getStatus().then((status) => this.setState({status}));
        }
        this.props.comments.subscribe(this.props.question.id, (comments) => {
            const knownIds = new Set(this.state.comments.map(c => c.uuid));
            comments = comments.filter(c => !knownIds.has(c.uuid));
            this.setState({ comments: [ ...this.state.comments, ...comments ]});
        });
    }

    showPopup(event: any) {
        event.preventDefault();
        this.setState({ popupAnchor: event.currentTarget });
    }

    hidePopup() {
        this.setState({ popupAnchor: null });
    }

    async sendComment() {
        await Backend.instance.postComment(this.props.reportId, this.state.pendingComment, this.props.question.id);
        this.setState({ canSend: false, pendingComment: '' });
    }

    textFieldProps(): TextFieldProps {
        const { status, canSend } = this.state;

        const props: TextFieldProps = {
            className: "commentInput",
            variant: "outlined",
            placeholder: "Write a comment",
            type: "text",
            value: this.state.pendingComment,
            InputProps: {
                endAdornment: <InputAdornment position="end">
                    <IconButton onClick={() => this.sendComment()} disabled={!canSend}>
                        <SendIcon  opacity={canSend ? 1: 0.5} color="primary"/>
                    </IconButton>
                </InputAdornment>
            }
        };

        if (status && status.features.comments) {
            if (canSend) {
                props.onKeyDown = (e) => {
                    if (e.keyCode === 13) {
                        this.sendComment();
                    }
                }
            }
            props.onChange = (event: any) => {
                const newCanSend = event.target.value.length !== 0;
                this.setState({ canSend: newCanSend, pendingComment: event.target.value });
            }
        } else {
            props.inputProps = {
                onKeyDown: this.showPopup,
            }
        }

        return props;
    }

    renderComment(c: ReportComment) {
        let { username, name } = c.author;
        if (name) {

        } else {
            const parts = username.split('@');
            if (parts.length > 1) {
                name = parts[0].split('.').join(' ');
            } else {
                name = username;
            }
        }
        return <div className="comment">
            <span className="author">{name}</span>
            &nbsp;
            <span className="message">{c.message}</span>
        </div>;
    }

    renderComments() {
        const { limit } = this.state;
        let showMore = false;
        let { comments } = this.state;
        if (limit && comments.length > limit) {
            showMore = true;
            comments = comments.slice(comments.length-3);
        }

        const elements = comments.map(this.renderComment);
        if (showMore) {
            elements.push(
                <a href="#" className="showMore"
                    onClick={(e) => { e.preventDefault(); this.setState({ limit: 0 }) }}>Show more comments</a>
                );
        }
        return elements;
    }

    render() {
        const { question } = this.props;
        const { popupAnchor } = this.state;

        const isShowingPopup = Boolean(this.state.popupAnchor);
        const id = isShowingPopup ? `comment-${question.id}-popup` : undefined;

        return <>
            <TextField {...this.textFieldProps()}/>
            {this.renderComments()}
            <Popper id={id} open={isShowingPopup} anchorEl={popupAnchor} transition className="tooltip">
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={1000} onEntered={() => setTimeout(this.hidePopup, 1000)}>
                        <Typography>Commenting feature is disabled</Typography>
                    </Fade>
                )}
            </Popper>
        </>;
    }
}